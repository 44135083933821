/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { NewsFeed } from '@organization/investorflow/build/NewsFeed';
import axios from 'axios';



const InvestorFlowNews = () => {
  const [hotNews, setHotNews] = useState([]);
  const [hrefTarget, setHrefTarget] = useState("_top");
  const [isReady, setIsReady] = useState(false);
  const [itemCount, setItemCount] = useState(null);
  const [onError, setOnError] = useState(null);
  const [latestNews, setLatestNews] = useState([]);

  const containerRef = useRef();
  let resizeObserver;

  const endpoint = process.env.REACT_APP_BUILD === 'development' ? 'http://localhost:7071/api' : 'https://investorflowanywhereserverless.azurewebsites.net/api';
  const serverlessEndpoint = `${endpoint}/investorflow-news`;
  // const serverlessEndpoint = `https://investorflowanywhereserverless.azurewebsites.net/api/investorflow-news`;

  /* START RESIZE */
  if (!resizeObserver && containerRef?.current && window.parent) {
    resizeObserver = new ResizeObserver((entries) => {
      const detail = {
        typeOfOperation: "window_resize",
        cmpId: window.name,
        height: `${entries[0].contentRect.height}px`
        // height: `${entries[0].contentRect.height}`
      };
      window.parent.postMessage(JSON.stringify(detail), "*");
    });
    resizeObserver.observe(containerRef.current);
  };
  /* END RESIZE */

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (window && !isReady) {
      if (window.parent) {
        // window.parent.postMessage('INVESTORFLOW_READY', "*");
        setIsReady(true);
      }
    };
  }, [isReady]);

  useEffect(() => {
    if ( window !== window.parent ) {
      setHrefTarget("_blank");
    };
  }, []);

  const checkParams = (articleCount) => {
    const url = window.location.href;
    if (url.includes('?items')) {
      const items = window.location.href.split('?items=')
      setItemCount(parseFloat(items?.[1]));
    } else {
      setItemCount(parseFloat(articleCount));
    };
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(serverlessEndpoint);
      const data = response?.data?.sources;
      // console.log('articles', data);
      setLatestNews(data);
      checkParams(data.length);
      const tempHots = [];
      data?.forEach((news) => {
        if (news.isHotNews) { tempHots.push(news) };
      });
      setHotNews(tempHots);
      setOnError(false);
    } catch (error) {
      setOnError(true);
      console.log('error fetching from serverless function', error);
    };
  };


  return (
    <div ref={containerRef}>
      {
       ((latestNews?.length > 0) || onError)
        ? <NewsFeed content={latestNews} itemCount={itemCount} hotNews={hotNews} hrefTarget={hrefTarget} error={onError} />
        : <></>
      }
    </div>
  );
};

export default InvestorFlowNews;
